import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Field,
  FieldLabel,
  Link,
  Text,
  toast,
  useSendContext,
  useDebounce,
} from 'taulia-ui';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LoginPageError from './LoginPageError';
import { AuthTypeContext } from '../../contexts/authTypeProvider';
import { authCodeIsValid, removeHyphensAndWhitespace } from '../../utils';
import { useQuery } from '../../hooks';
import GLOBALS from '../../globals';

function SecondFactorLogin() {
  const { authType, setAuthType } = useContext(AuthTypeContext);
  const query = useQuery();
  const { pathname } = useLocation();
  const [rememberDevice, setRememberDevice] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [phonenumber] = useState(
    query.get('phoneNumber') ? query.get('phoneNumber').slice(-2) : ''
  );
  const { t } = useTranslation();

  const {
    data: sendSmsData,
    error: sendSmsError,
    doSend: doSendSms,
  } = useSendContext('POST', '/api/send-sms');

  const {
    data: loginData,
    error: loginError,
    loading: loginLoading,
    doSend: doLogin,
  } = useSendContext('POST', '/api/login/two-factor-authentication');

  const login = useDebounce(() => {
    doLogin({
      verificationCode: removeHyphensAndWhitespace(verificationCode),
      rememberDevice,
    });
  }, 500);

  const sendSms = () => {
    doSendSms();
  };

  const backupCodeLink = () => (
    <Link
      href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-How-to-enable-two-factor-authentication/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('authentication.login.backupCode')}
    </Link>
  );

  useEffect(() => {
    if (sendSmsData && sendSmsData.success) {
      toast.success(t('authentication.login.resendToast'));
    } else if (sendSmsError) {
      toast.error(t('authentication.smsSetup.codeFailure'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSmsData, sendSmsError]);

  useEffect(() => {
    if (loginData && loginData.success && loginData.redirectUrl) {
      window.location.assign(loginData.redirectUrl);
    } else if (loginData || loginError) {
      if (loginError) {
        setErrorCode('UNKNOWN_ERROR');
      } else {
        setErrorCode(loginData.reason);
      }
    }
  }, [loginData, loginError]);

  useEffect(() => {
    setAuthType(pathname.split('/').pop());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verificationCode.length === 6) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationCode]);

  return (
    <div className="login-container">
      <h2 className="auth-header">
        {t(`authentication.login.${authType?.toLowerCase()}Header`)}
      </h2>
      <p className="centered">
        <Trans
          i18nKey={`authentication.login.${authType?.toLowerCase()}InfoText`}
          components={[
            authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.SMS ? (
              <strong />
            ) : (
              backupCodeLink()
            ),
          ]}
          values={{ phonenumber: phonenumber.slice(-2) }}
        />
        {authType === GLOBALS.TWO_FACTOR_AUTH_TYPE.SMS && (
          <Button onClick={sendSms} theme="text">
            {t('authentication.login.resendCode')}
          </Button>
        )}
      </p>
      <form
        onSubmit={event => {
          event.preventDefault();
          login();
        }}
      >
        {errorCode && <LoginPageError errorCode={errorCode} />}
        <Field>
          <FieldLabel htmlFor="verification-code">
            {t('authentication.login.verificationCode')}
          </FieldLabel>
          <Link
            href="https://support.taulia.com/articles/en_US/Agent_Knowledge_Base/Q-How-to-enable-two-factor-authentication/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ float: 'right' }}
          >
            {t('footer.help')}
          </Link>
          <Text
            aria-label="verification-code-input"
            id="verification-code"
            inputMode="numeric"
            onChange={e => setVerificationCode(e.target.value)}
            pattern="[0-9]*"
            value={verificationCode}
          />
        </Field>
        <Field>
          <Checkbox
            aria-label="remember-email-checkbox"
            checked={rememberDevice}
            onChange={() => setRememberDevice(!rememberDevice)}
          >
            {t('authentication.login.rememberDevice')}
          </Checkbox>
        </Field>
        <Button
          disabled={!authCodeIsValid(verificationCode)}
          type="submit"
          theme="primary"
          block
          spinning={
            loginLoading ||
            (loginData && loginData.redirectUrl && loginData.success)
          }
        >
          {t('loginPage.loginAction')}
        </Button>
        <p className="centered">
          <Trans
            i18nKey="authentication.login.enterCode"
            components={[
              <Link
                data-testid="recovery-code-login-link"
                href="/login/two-factor/recovery"
              />,
            ]}
          />
        </p>
      </form>
    </div>
  );
}

export default SecondFactorLogin;
